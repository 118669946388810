import React from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

const Privacy = () => (
  <Layout>
    <SEO
      title="Privacy | Privacy Policy for Code Championship"
      description="Code Championship understands how important privacy is to our community, especially kids and parents. Learn about our policy here."
      keywords={['privacy', 'privacy policy']}
    />
    <h1>Code Championship Virtual Event Policy</h1>
    <div className="stripe-hr thin" />
    <div className="inner info-inner">
      <section>
        <p>
          <i>The Code Championship Virtual Event Policy was last updated: July 28, 2020</i>
        </p>
        <p className="intro">Code Championship understands how important privacy is to our community, especially kids and parents. We wrote this Privacy Policy to explain what information we collect through our virtual events, and how you can contact us if you have any questions or concerns.</p>
      </section>
      <section id="virtual-event">
        <ul>
          <li>
            Participants who share their video will be visible to organizers and other participants.
          </li>
          <li>
            Participants who use their microphone will be audible to organizers
            and other participants.
          </li>
          <li>
            Participants who share their screen will allow organizers
            and other participants to view their screen.
          </li>
        </ul>
        <p>
          This video conference will not be public; however,
          we will be recording the game play (the bots playing head to head) and may
          share video recordings along with the first name and last initial of the coder.
          Code Championship use these recordings to encourage others to code
          through our marketing and promotional materials.
        </p>
      </section>
    </div>
  </Layout>
);

export default Privacy;
